import cn from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import {Button} from '@components/widgets/Button/Button';
import {dealerConfigOptions} from '@helpers/config-master.mjs';
import {useState} from 'react';
import s from './CartSidebarView.module.css';
import ShippingIcon from '@image/icons/png-icons/ShippingTruckSmallIcon.png';
import {CartSidebarDesktopCartItem} from './CartSidebarDesktopCartItem';
import {
  CartSidebarMobileCartItem
} from '@components/cart/CartSidebarView/CartSidebarMobileCartItem';
import {Check, Cross} from '@components/widgets/Icons';
import {Bag} from '../../widgets/Icons';
import {useCartUI} from '@components/cart/CartSideCartUiProvider';
import {formatPriceNumeric} from '@components/product/global/formatPriceNumeric';
import {useRouter} from 'next/router';
import {useDatabaseCartProviderV3} from '@components/cart/CartProvider.jsx';
import {niceMoney, roundMoneyFair} from '@helpers/common/number.mjs';
import {SubwooferCompatibilityCheck} from '../../vehicle-specific/compatibility/sub-compat-check';
import {
  getOnlyIncompatibleSkuList,
  getSubwooferCompatibilityChecksForEnclosures,
  pushIncompatibleDown,
  pushPreorderDown
} from '@helpers/subwoofer-compatibility/get-compatibility-mappings.mjs';
import {SidebarLayout} from '@components/sidebar/SidebarLayout';
import {useMobileOrTablet} from '@helpers/hooks/useWindow';
import {Spinner} from '@components/widgets/Common';
import {filterOutHiddenCartItemsSkus} from '@helpers/collections/filters.mjs';

export const CartSidebarMainComponent = () => {
  const isMobile = useMobileOrTablet();
  const router = useRouter();
  const {closeCart} = useCartUI();
  const [updating, setUpdating] = useState(false);
  const {
    session,
    isLoadedOnce,
    cart_token,
    isDealer,
    lineItems: originalLineItems,
    totals,
    isEmpty,
    isSaleEnabled
  } = useDatabaseCartProviderV3();
  // console.log("[CartSidebarMainComponent][originalLineItems]:", {originalLineItems});

  const inCompatibleSubwooferLineItems =
    getSubwooferCompatibilityChecksForEnclosures(originalLineItems);

  let lineItems = originalLineItems;
  if (inCompatibleSubwooferLineItems?.length) {
    const onlyIncompatibleSubwoofers = getOnlyIncompatibleSkuList(inCompatibleSubwooferLineItems);
    lineItems = pushIncompatibleDown(lineItems, onlyIncompatibleSubwoofers);
  }

  lineItems = pushPreorderDown(lineItems);
  lineItems = remapFordEnclosureDisplaySkus_TEMPORARY_FUNCTION(lineItems);
  // console.log("[CartSidebarMainComponent][lineItems]:", {lineItems})
  const subTotal = formatPriceNumeric(totals.subTotal);
  const totalDiscounts = totals.discountsTotal;
  const totalSavings = formatPriceNumeric(totalDiscounts);
  const hasSavingsAmount = totalDiscounts > 0;

  const hasSomePreorderProducts = lineItems?.some((item) => {
    const preorderInfo = item?.variant?.preorderInfo;
    const isPreorderItem =
      preorderInfo?.is_pre_order_eligible && preorderInfo?.is_pre_order_enabled;
    const shouldSendAsPreOrderedItem = !item?.variant?.currentlyInStock && isPreorderItem;
    return shouldSendAsPreOrderedItem;
  });

  const isNotDealerAndHasDiscountAmount = !isDealer && hasSavingsAmount;

  const handleClose = () => {
    console.log('[handleClose]');
    closeCart();
  };

  const sendCheckoutEventToDB = async (items) => {
    const simpleItems = items?.map(({sku, price, quantity, price_map, price_msrp}) => ({
      sku,
      price,
      quantity,
      price_map,
      price_msrp
    }));
    const user = session?.user;
    const postBody = {
      serverOrClient: 'client',
      eventName: 'go_to_checkout_button_clicked',
      accountId: user?.account_id,
      customerEmail: user?.email,
      cartToken: cart_token,
      lineItems: simpleItems
    };
    await fetch(`/api/checkout-v4/save-checkout-event`, {
      method: 'POST',
      headers: {'content-type': 'application/json'},
      body: JSON.stringify(postBody)
    });
  };

  const onGoToCheckout = async () => {
    sendCheckoutEventToDB(lineItems);
    document.querySelector('body').scrollIntoView();
    await router.push('/checkout/v3');
    handleClose();
  };

  const showMobileSidebarLayout = isMobile === true;
  const showDesktopSidebarLayout = isMobile === false;
  // console.log({showMobileSidebarLayout, showDesktopSidebarLayout});

  const error = null;
  const success = null;

  // console.log({isEmpty});
  const filteredLineItems = filterOutHiddenCartItemsSkus(lineItems);

  const shippingTotal = totals?.shippingTotal;
  const subtotalWithShipping = niceMoney(totals?.subTotal + shippingTotal);

  const isDealerNotReachesFreeShipping = isDealer && totals?.subTotal < dealerConfigOptions?.freeShippingThreshold;
  const isDealerReachesFreeShipping = isDealer && totals?.subTotal >= dealerConfigOptions?.freeShippingThreshold;
  // console.log('[CartSidebarMainComponent][isDealerReachesFreeShipping]:', isDealerReachesFreeShipping);

  return (
    <SidebarLayout
      className={cn({[s.empty]: isEmpty}, `rounded-2xl`)}
      hideHeader={true}
      style={{borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}}>
      {isEmpty ? (
        <IfCartIsEmpty handleClose={handleClose}/>
      ) : error ? (
        <ErrorMessageCouldNotProcess/>
      ) : success ? (
        <SuccessPaymentMessage/>
      ) : (
        <>
          <div className="flex-1" id="cart-sidebar-view">
            <div className="flex justify-between items-center py-4 pl-3 pr-2 md:pr-3">
              <Link
                href="/cart"
                className="hover:underline outline-none focus:outline-none"
                style={{fontSize: '18px'}}
                onClick={handleClose}
                aria-label="Shopping Cart">
                Cart
              </Link>

              <div className="flex items-center">
                <button
                  onClick={handleClose}
                  aria-label="Close cart"
                  className="transition ease-in-out duration-150 flex items-center focus:outline-none">
                  <Cross className="h-6 w-6 text-gray-500 hover:text-gray-600 ml-2"/>
                </button>
              </div>
            </div>

            {isDealer && (
              <div className="">
                <DealerShippingProgressBar subTotal={totals?.subTotal}/>
              </div>
            )}

            {showDesktopSidebarLayout && (
              <ShowSidebarDesktopCartLineItems
                lineItems={filteredLineItems}
                updating={updating}
                setUpdating={setUpdating}
              />
            )}

            {showMobileSidebarLayout && (
              <ShowCartLineItemsMobile
                lineItems={filteredLineItems}
                updating={updating}
                setUpdating={setUpdating}
              />
            )}

            <div className="p-3">
              <SubwooferCompatibilityCheck handleClose={handleClose}/>
            </div>
          </div>

          {hasSomePreorderProducts && (
            <div className="pt-15 pb-30">
              <PreOrderItemNoticeSideCart/>
            </div>
          )}

          <div
            className="sticky bottom-0 border border-t-neutral-200 shadow-[rgba(17,_17,_26,_0.07)_0px_0px_16px] md:shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
            <div
              className="flex-shrink-0 px-4 sm:px-6 sticky z-20 bottom-0 w-full right-0 left-0 text-sm bg-accent-0 pt-4 pb-2">
              {isNotDealerAndHasDiscountAmount && (
                <div className="flex justify-between py-1 text-base">
                  <div>Original Total</div>
                  <div className="flex">
                    <div className="line-through">{niceMoney(totals?.originalTotal)}</div>
                  </div>
                </div>
              )}

              {/*style={{color: 'rgba(4,43,138, 1)'}}>*/}
              {/*style={{color: 'rgba(184,20,1)'}}>*/}

              <div>
                {isLoadedOnce && isSaleEnabled && totalSavings > 0 && hasSavingsAmount && !isDealer && (
                  <div
                    className="flex justify-between py-1 relative items-end text-base font-bold"
                    style={{color: 'rgba(0,102,204, 1)'}} // Updated to a blue tone
                  >
                    {/*<div>Total Sale Savings:</div>*/}
                    <div>New Year Sale Savings:</div>
                    <div className="tracking-wide flex">
                      {updating &&
                        <Spinner color="text-gray" size={16} className="mt-1.5 mr-2"/>}
                      <span>-{niceMoney(totalSavings)}</span>
                    </div>
                  </div>
                )}


                {isDealerReachesFreeShipping &&
                  <DealerFreeShipping subTotal={totals?.originalTotal}
                  />
                }

                {isDealerNotReachesFreeShipping &&
                  <DisplayShippingCost shippingTotal={shippingTotal}/>
                }

                {!isDealer && shippingTotal > 0 &&
                  <DisplayShippingCost shippingTotal={shippingTotal}/>
                }

                {!isDealer && totals?.shippingTotal === 0 &&
                  <FreeShippingIcon/>
                }

                <div className="pb-2">
                  <div className="flex justify-between py-1 text-base">
                    <div>Subtotal</div>
                    <div className="flex">
                      {updating && <Spinner color="text-gray" size={16} className="mt-1.5 mr-1"/>}
                      {subtotalWithShipping}
                    </div>
                  </div>
                </div>

                <div className={'pt-2'}>
                  <Button
                    variant="slim"
                    width="100%"
                    className="my-3"
                    type="button"
                    onClick={async (e) => {
                      e.preventDefault();
                      await onGoToCheckout();
                    }}
                    disabled={false}>
                    Proceed to Checkout
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </SidebarLayout>
  );
};

const ShowSidebarDesktopCartLineItems = ({lineItems, updating, setUpdating}) => {
  // console.log('[ShowSidebarDesktopCartLineItems][lineItems]:', {lineItems});
  return (
    <ul className={s?.lineItemsList}>
      {lineItems?.map((item, index) => {
        let isCompatible = item?.isCompatible;
        if (isCompatible === undefined) {
          isCompatible = 'N/A';
        }
        // console.log("[ShowSidebarDesktopCartLineItems][item.isCompatible]:", {isCompatible});
        return (
          <li key={`item-${index}`} className="bg-white pl-1 pr-3 border-b">
            <CartSidebarDesktopCartItem
              item={item}
              updating={updating}
              setUpdating={setUpdating}
              isCompatible={isCompatible}
            />
          </li>
        );
      })}
    </ul>
  );
};

const ShowCartLineItemsMobile = ({lineItems, updating, setUpdating}) => {
  // console.log("[ShowCartLineItemsMobile][lineItems]:", {lineItems});
  return (
    <ul className={s?.lineItemsList}>
      {lineItems?.map((item, index) => {
        let isCompatible = item?.isCompatible;
        if (isCompatible === undefined) {
          isCompatible = 'N/A';
        }

        return (
          <li
            key={`mobile-item-${item?.sku}-${index}`}
            className="bg-white pl-1 border-b last:border-b-0">
            <CartSidebarMobileCartItem
              item={item}
              isCompatible={isCompatible}
              updating={updating}
              setUpdating={setUpdating}
            />
          </li>
        );
      })}
    </ul>
  );
};

function ErrorMessageCouldNotProcess() {
  return (
    <div className="flex-1 px-4 flex flex-col justify-center items-center">
      <span className="border border-white rounded-full flex items-center justify-center w-16 h-16">
        <Cross width={24} height={24}/>
      </span>
      <h2
        className="pt-6 text-xl font-light text-center">{`We couldn’t process the purchase. Please check your card information and try again.`}</h2>
    </div>
  );
}

function SuccessPaymentMessage() {
  return (
    <div className="flex-1 px-4 flex flex-col justify-center items-center">
      <span className="border border-white rounded-full flex items-center justify-center w-16 h-16">
        <Check/>
      </span>
      <h2 className="pt-6 text-xl font-light text-center">Thank you for your order.</h2>
    </div>
  );
}

function IfCartIsEmpty({handleClose}) {
  return (
    <div>
      <div className="flex justify-end p-4">
        <button
          onClick={handleClose}
          aria-label="Close Cart"
          className="transition ease-in-out duration-150 flex items-center focus:outline-none">
          <Cross className="h-6 w-6 text-gray-500 hover:text-gray-600 ml-2"/>
        </button>
      </div>
      <div className="flex-1 px-4 flex flex-col justify-center items-center">
        <span
          className="border border-dashed border-primary rounded-full flex items-center justify-center w-16 h-16 p-12 bg-secondary text-secondary">
          <Bag className="absolute"/>
        </span>
        <h2 className="pt-6 text-2xl font-bold tracking-wide text-center">Your cart is empty</h2>
        <p className="text-accent-3 px-10 text-center pt-2"/>
      </div>
    </div>
  );
}

export function PreOrderItemNoticeSideCart() {
  return (
    <div className="pl-2 pr-2">
      <div
        className="flex-1 flex pl-2 pr-2 text-center align-center justify-center
				border yellow-notice-bg rounded">
        <div className="gap-2 my-1">
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '18px'
            }}>
            NOTICE: <span className="text-blue mb-1">Cart Contains Pre-Ordered Items</span>
          </div>
          <div>
            <span className="fs-6 fw-normal">These items are set to ship at a later date.</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const DisplayShippingCost = ({shippingTotal}) => {
  // console.log('[DisplayShippingCost][shippingTotal]:', shippingTotal);
  return (
    <div className="flex justify-between py-1 text-[16px]">
   <span className="relative flex items-end">
        Shipping
        <div className="h-6 w-6 ml-1 align-middle" style={{paddingTop: '5.8px'}}>
          <Image
            src={ShippingIcon}
            alt="🚚"
            style={{objectFit: 'contain'}}
            width={200}
            height={200}
          />
        </div>
      </span>
      <span>{niceMoney(shippingTotal)}</span>
    </div>
  );

};

const FreeShippingIcon = () => {
  return (
    <div className="flex justify-between py-1 text-base">
      <span className="relative flex items-end">
        Shipping
        <div className="h-6 w-6 ml-1 align-middle" style={{paddingTop: '5.8px'}}>
          <Image
            src={ShippingIcon}
            alt="🚚"
            style={{objectFit: 'contain'}}
            width={200}
            height={200}
          />
        </div>
      </span>
      <span className="tracking-wide">FREE</span>
    </div>
  );
};

export const DealerShippingProgressBar = ({subTotal}) => {
  const isMobile = useMobileOrTablet();
  if (subTotal >= dealerConfigOptions?.freeShippingThreshold) {
    return null;
  }

  const amountRequired = dealerConfigOptions?.freeShippingThreshold - subTotal;
  const percent = roundMoneyFair((subTotal / dealerConfigOptions?.freeShippingThreshold) * 100);

  return (
    <div
      className="py-3 border-bottom"
      style={{
        borderTop: 'solid 1px silver',
        borderBottom: 'solid 1px silver'
      }}>
      <div
        className="md:text-lg relative flex items-end mx-3"
        style={{
          fontSize: isMobile ? '11pt' : '18px'
        }}>
        Add {niceMoney(amountRequired)} for &nbsp;
        <div
          className=""
          style={{
            fontWeight: 'bold',
            fontSize: isMobile ? '11pt' : '18px',
            verticalAlign: 'bottom'
          }}>
          FREE SHIPPING!
        </div>
        <div className="h-6 w-7 ml-1 align-middle hidden md:block">
          <Image
            src={ShippingIcon}
            alt="🚚"
            style={{objectFit: 'contain'}}
            width={200}
            height={200}
          />
        </div>
      </div>
      <div className="bg-gray-200 rounded-full h-3 mt-1 ml-3 mr-3">
        <div
          className="h-3 rounded-full"
          style={{
            width: percent + '%',
            backgroundColor: '#0989a6'
          }}
        />
      </div>
    </div>
  );
};

export function DealerFreeShipping({subTotal}) {
  if (subTotal >= dealerConfigOptions.freeShippingThreshold) {
    return (
      <div className="flex justify-between py-1">
        <span className="text-lg relative flex items-end">
          Shipping
          <div className="h-6 w-7 ml-1 align-middle">
            <Image
              src={ShippingIcon}
              alt="🚚"
              style={{objectFit: 'contain'}}
              width={200}
              height={200}
            />
          </div>
        </span>
        <span>FREE 🎉</span>
      </div>
    );
  }
  return null;
}

const remapFordEnclosureDisplaySkus_TEMPORARY_FUNCTION = (lineItems) =>
  lineItems?.map((oneLineItem) => {
    let displayedSku = oneLineItem?.sku || oneLineItem?.variant?.sku;
    if (displayedSku?.includes('FORD-2009-UP')) {
      displayedSku = displayedSku.replace('FORD-2009-UP', 'FORD-2015-UP');
    }
    oneLineItem.displayed_sku = displayedSku;
    return oneLineItem;
  });
