import qs from 'qs';
import querystring from 'querystring';
import {ErrorWithDetails} from '../common/error.mjs';
import fetch from 'cross-fetch';

export const createUrlWithSkuListAsQueryParams = (apiUrl, skuList) => {
  // console.log("[createUrlWithSkuListAsQueryParams] skuList:", skuList)
  if (!skuList?.length) {
    return;
  }
  skuList = skuList.map(sku => encodeURIComponent(sku)).join(',');
  const query = querystring.stringify({skuList: skuList});
  const urlWithSkuList = `${apiUrl}?${query}`;
  return urlWithSkuList;
};

export const fetcher = async (url, options = {}, pathName = null) => {
  const headers = {...options.headers};
  if (pathName) {
    headers['fe-pathname'] = pathName;
    // console.log("[fetcher] pathName", pathname);
  }
  const res = await fetch(url, {...options, headers});
  if (!res?.ok) {
    console.error(url, res?.status, res?.statusText);
    const error = new ErrorWithDetails('An error occurred while fetching the data.', {
      url,
      res
    });
    error.info = await res?.json();
    error.status = res?.status;
    throw error;
  }
  return res?.json();
};

export async function fetchWithCatch(url, options = {}) {
  const res = await fetch(url, options);
  let contentLength = parseInt(res?.headers?.get('content-length'), 10);
  const responseText = await res?.text();
  // console.log({ responseText });
  if (!res?.ok) {
    console.error(responseText);
    console.error(options);
    if (contentLength > 0) {
      throw new Error(responseText);
    }
    throw new Error(res.statusText);
  }
  if (contentLength === 0) {
    return res?.status;
  }

  return responseText ? JSON?.parse(responseText) : res?.status;
}

export const file = (output, contentType, fileName, destination) => {
  return {
    statusCode: 200,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      'Content-Type': contentType,
      'Content-Disposition': `attachment; filename="${fileName}"`,
      'X-Drive-File-Size': output?.length
    },
    body: output.toString('base64'),
    isBase64Encoded: true,
    destination: destination
  };
};

export const buildUrlWithQueryParams = (url, params = {}) => {
  const queryParams = qs?.stringify(params);
  return url + '?' + queryParams;
};

export const isofetch = {
  async request(url, type, data = null, headers = {}) {
    let args = {
      headers: {
        Accept: 'application/json',
        ...headers,
      },
      method: type,
    };

    if (type === 'POST' || type === 'PUT' || type === 'PATCH') {
      if (data instanceof FormData) {
        // Automatically detect FormData and let the browser handle Content-Type
        args.body = data;
      } else if (typeof data === 'object' && data !== null) {
        // For JSON data
        args.headers['Content-Type'] = 'application/json';
        args.body = JSON.stringify(data);
      } else {
        // Handle other types (e.g., plain text)
        args.body = data;
      }
    }

    try {
      const response = await fetch(url, args);
      const contentType = response.headers.get('Content-Type') || '';

      // Automatically parse JSON if the response type is JSON
      if (contentType.includes('application/json')) {
        const json = await response.json();
        if (!response.ok) {
          throw json;
        }
        return json;
      }

      // Return text for non-JSON responses
      const text = await response.text();
      if (!response.ok) {
        throw text;
      }
      return text;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  get(url, headers = {}) {
    return this.request(url, 'GET', null, headers);
  },

  post(url, data, headers = {}) {
    return this.request(url, 'POST', data, headers);
  },

  put(url, data, headers = {}) {
    return this.request(url, 'PUT', data, headers);
  },

  patch(url, data, headers = {}) {
    return this.request(url, 'PATCH', data, headers);
  },

  delete(url, headers = {}) {
    return this.request(url, 'DELETE', null, headers);
  },
};

