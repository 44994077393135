import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    console.log("[getDerivedStateFromError]", error);
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("[componentDidCatch] Uncaught error: ", {
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      console.log("[ERROR RECOGNIZED IN ErrorBoundary.jsx]: ", this.state.hasError);
      return <div>
        <h2>Oops, there is an error!</h2>
        <button
          type="button"
          onClick={() => this.setState({hasError: false})}
        >
          Try again?
        </button>
      </div>
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
