import {siteSettingsV2} from '@helpers/config-master.mjs';
import {getEndOfDay, useCountdown} from '@components/widgets/CountdownTimer/useCountdownTimer';

export const CountdownTimer = () => {
  const saleAttributes = siteSettingsV2.getSaleSettings();
  // console.log({saleAttributes});

  const saleIsActive = saleAttributes.saleIsActive;
  const showCountdownTimer = saleAttributes?.layoutSettings?.showCountdownTimer;

  const isBlackFridaySale = saleAttributes?.name?.startsWith('BF');
  const isCyberMonday = saleAttributes?.name?.startsWith('CM');
  const saleExpiresAt = isBlackFridaySale || isCyberMonday
    ? saleAttributes?.saleExpiresAt
    : getEndOfDay(); // Use getEndOfDay() if neither BF nor CM

  const [days, hours, minutes, seconds] = useCountdown(saleExpiresAt, saleIsActive);

  if (saleIsActive && showCountdownTimer) {
    if (isBlackFridaySale || isCyberMonday) {
      return <ShowCounterBFCM days={days} hours={hours} minutes={minutes} seconds={seconds}/>;
    } else {
      return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds}/>;
    }
  } else {
    return null;
  }
};


const ShowCounter = ({days, hours, minutes, seconds}) => {
  const daysIsDanger = days <= 2 && days > 1;

  const hourText = hours <= 1 ? 'Hour' : 'Hours';

  const isValidCountdownTime = days + hours + minutes + seconds > 0;
  return (
    <div className='sale-countdown-timer h-[80px] lg:h-[100px]'>
      {isValidCountdownTime ? (
        <>
          <div className='sale-label'>Sale Ends:</div>
          <div className='countdown-link shadow-xl'>
            <DateTimeDisplay value={days} type={'Days'} isDanger={daysIsDanger}/>
            <DateTimeDisplay value={hours} type={hourText} isDanger={hours <= 24}/>
            <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false}/>
            <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false}/>
          </div>
        </>
      ) : null}
    </div>
  );
};

const DateTimeDisplay = ({value, type, isDanger}) => {
  const zeroStyle = value === 0 && type === 'Days' ? {color: 'rgba(238,237,237,0.73)'} : {};
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <div style={zeroStyle}>{value}</div>
      <span style={zeroStyle}>{type}</span>
    </div>
  );
};

const BFCMSaleLabel = () => {
  return (
    <div className="sale-label -mt-3">
      <svg
        viewBox="0 0 700 100"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full h-[50px] lg:h-[65px] -mb-4 lg:-mb-7"
      >
        <defs>
          <linearGradient id="gradient-white" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{stopColor: '#FFFFFF', stopOpacity: 1}}/>
            <stop offset="100%" style={{stopColor: '#F9F9F9', stopOpacity: 1}}/>
          </linearGradient>
          <linearGradient id="gradient-blue" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{stopColor: '#00BFFF', stopOpacity: 1}}/>
            <stop offset="100%" style={{stopColor: '#1E90FF', stopOpacity: 1}}/>
          </linearGradient>
          <filter id="text-shadow" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" result="blur"/>
            <feOffset in="blur" dx="1" dy="1" result="offsetBlur"/>
            <feMerge>
              <feMergeNode in="offsetBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        <text
          x="35%"
          y="60%"
          textAnchor="middle"
          dy=".35em"
          fontSize="50"
          fontWeight="bold"
          fill="url(#gradient-white)"
          filter="url(#text-shadow)"
          transform="skewX(-5)"
        >
          NEW YEAR SALE
        </text>
        <text
          x="78%"
          y="60%"
          textAnchor="middle"
          dy=".35em"
          fontSize="42"
          fontWeight="bold"
          fill="url(#gradient-blue)"
          filter="url(#text-shadow)"
          letterSpacing="1"
          transform="skewX(-3)"
        >
          Sale Ends
        </text>
      </svg>
    </div>
  );
};

const ShowCounterBFCM = ({days, hours, minutes, seconds}) => {
  const daysIsDanger = days <= 2 && days > 1;
  const hourText = hours <= 1 ? 'Hour' : 'Hours';
  const isValidCountdownTime = days + hours + minutes + seconds > 0;

  return (
    <div className="sale-countdown-timer flex flex-col items-center justify-center">
      {isValidCountdownTime ? (
        <>
          <BFCMSaleLabel/>
          <div className="countdown-link shadow-xl flex space-x-2">
            <DateTimeDisplay value={days} type={'Days'} isDanger={daysIsDanger}/>
            <DateTimeDisplay value={hours} type={hourText} isDanger={hours <= 24}/>
            <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false}/>
            <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false}/>
          </div>
        </>
      ) : null}
    </div>
  );
};


const ExpiredNotice = () => {
  return (
    <div className='expired-notice'>
      <span>Sorry, our sale has ended!</span>
      <p>Check back soon for new savings & deals.</p>
    </div>
  );
};
