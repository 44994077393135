import {homePageThemeFolderName} from '@helpers/config-master.mjs';
import cn from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import {pathJoin} from '@helpers/common/string.mjs';
import {HStack, VStack} from '@components/widgets/Common';

export const HomePageCollectionImages = () => {
  return {
    imagePath: `/image/home/theme/sale/desktop/${homePageThemeFolderName}`,
    imageMobilePath: `/image/home/theme/sale/mobile/${homePageThemeFolderName}`,
    sections: [
      {
        img: `/image/home/theme/sale/desktop/${homePageThemeFolderName}/NewYearSale2025-Amplifiers-Desktop.webp`,
        imgMobile: `/image/home/theme/sale/mobile/${homePageThemeFolderName}/NewYearSale2025-Amplifiers-Mobile.webp`,
        link: '/collections/car-amplifiers',
        text: 'Everything from our ultra-compact series to competition grade mono-blocks. Your next amplifier is waiting.',
        button: 'Shop Amplifiers'
      },
      {
        img: `/image/home/theme/sale/desktop/${homePageThemeFolderName}/NewYearSale2025-Subwoofers-Desktop.webp`,
        imgMobile: `/image/home/theme/sale/mobile/${homePageThemeFolderName}/NewYearSale2025-Subwoofers-Mobile.webp`,
        link: '/collections/car-subwoofers',
        text: 'Whether you are looking for an entry level daily system or looking to build a SPL vehicle, we have a solution for you.',
        button: 'Shop Subwoofers'
      },
      {
        img: `/image/home/theme/sale/desktop/${homePageThemeFolderName}/NewYearSale2025-Speakers-Desktop.webp`,
        imgMobile: `/image/home/theme/sale/mobile/${homePageThemeFolderName}/NewYearSale2025-Speakers-Mobile.webp`,
        link: '/collections/car-speakers',
        text: 'Everything from coaxial speakers and 2-way component systems, to pro audio mid-ranges and super tweeters.',
        button: 'Shop Speakers'
      },
      {
        img: `/image/home/theme/sale/desktop/${homePageThemeFolderName}/NewYearSale2025-Bundles-Desktop.webp`,
        imgMobile: `/image/home/theme/sale/mobile/${homePageThemeFolderName}/NewYearSale2025-Bundles-Mobile.webp`,
        link: '/collections/sdr-series-loaded-bass-packages',
        text: 'Skar Audio Complete Bass Packages take the hard part out of shopping for your next system. We offer entry level systems all the way up to competition grade systems for any ride.',
        button: 'Shop Bass Packages'
      }
    ]
  };
};

export const HomePageFeaturedCollectionBannersDesktop = ({homePageThemeFolderName}) => {
  const CollectionImages = HomePageCollectionImages(homePageThemeFolderName);
  // console.dir({CollectionImages}, {depth: null})

  return (
    <div className='m-auto md:max-w-[1300px] mb-12'>
      <HStack gap={5}>
        {CollectionImages?.sections?.map((section, index) => (
          <DesktopHomePageSectionCard
            key={index}
            section={section}
            imagePath={CollectionImages?.img}
          />
        ))}
      </HStack>
    </div>
  );
};

export const HomePageFeaturedCollectionBannersMobile = ({homePageThemeFolderName}) => {
  const CollectionImages = HomePageCollectionImages(homePageThemeFolderName);
  // console.log({collectionImages: CollectionImages})

  return (
    <VStack gap={0}>
      {CollectionImages?.sections?.map((section, index) => {
        return (
          <MobileHomePageSectionCard
            key={`id-${index}`}
            section={section}
            imagePath={CollectionImages?.imgMobile}
          />
        );
      })}
    </VStack>
  );
};

export const DesktopHomePageSectionCard = ({section, imagePath}) => {
  const imagePathSrc = pathJoin(imagePath, section?.img);
  // console.log({imagePath})
  return (
    <Link href={section?.link} passHref={true}>
      <div
        className='relative transform transition-transform duration-500 hover:-translate-y-1 hover:scale-105 cursor-pointer group'>
        <Image
          src={imagePathSrc}
          alt={section?.button}
          width={407}
          height={695}
          loading='eager'
          className={'transition-all duration-500 group-hover:shadow-lg'}
        />
        <div
          className={cn(
            'absolute bottom-0 top-0 left-0 w-full h-full text-center flex flex-col justify-end pb-3 transition-all duration-500 opacity-0',
            'group-hover:opacity-100'
          )}>
          <div
            className={
              'transition-all duration-500 translate-y-2 group-hover:translate-y-0 ease-in-out'
            }>
            <div className={cn('text-white lg:text-xl lg:px-5 sm:text-sm bg-black bg-opacity-50')}>
              {section?.text}
            </div>
            <div
              className={cn(
                'font-oswald uppercase sm:text-sm sm:p-1 sm:mx-5 mt-5 lg:p-3 lg:mt-5 lg:mx-10 lg:text-2xl bg-white'
              )}>
              {section?.button}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const MobileHomePageSectionCard = ({section, imagePath}) => {
  const imagePathSrc = pathJoin(imagePath, section?.imgMobile);

  return (
    <div className='pb-0.5'>
      <Link href={section?.link}>
        <Image
          src={imagePathSrc}
          width={800}
          height={400}
          className='align-bottom block'
          alt={'Skar Audio ' + section?.button}
          loading='eager'
        />
      </Link>
    </div>
  );
};
