import Image from 'next/image';
import Link from 'next/link';
import {useEffect, useState} from 'react';
import {TrashCan} from '@components/widgets/Icons';
import {useDatabaseCartProviderV3} from '@components/cart/CartProvider.jsx';
import {TwoPrices} from '@components/cart/CartItem/TwoPrices';
import {DisplayChildProductsForBundle} from '@components/product/global/BundleHelpers';
import {useCartUI} from '@components/cart/CartSideCartUiProvider';
import {QuantitySelector} from '@components/widgets/QuantitySelector/QuantitySelector';

const placeholderImg = '/product-img-placeholder.svg';

export const CartSidebarDesktopCartItem = ({item, updating, setUpdating}) => {
  // console.log('[CartSidebarDesktopCartItem][Item]:', {item});
  const {closeCart} = useCartUI();
  const {isDealer, updateLocalItem, deleteLocalItem} = useDatabaseCartProviderV3();
  const [quantity, setQuantity] = useState(item?.quantity);

  const closeSidebarIfPresent = () => {
    setTimeout(closeCart, 300);
  };

  const handleRemove = async (sku) => {
    // console.log(" >> Removing SKU from Cart:", sku)
    setUpdating(true);
    await deleteLocalItem(sku);
    setUpdating(false);
  };

  const handleChange = async (value) => {
    setUpdating(true);
    setQuantity(value);
    await updateLocalItem({
      sku: item.sku,
      quantity: value
    });
    setUpdating(false);
  };

  useEffect(() => {
    if (item?.quantity !== quantity) {
      setQuantity(item?.quantity);
    }
  }, [item?.quantity, quantity]);

  return (
    <SingleCartItem
      item={item}
      quantity={quantity}
      updating={updating}
      isDealer={isDealer}
      handleChange={handleChange}
      handleRemove={handleRemove}
      closeSidebarIfPresent={closeSidebarIfPresent}
    />
  );
};

const SingleCartItem = ({
  item,
  quantity,
  updating,
  isDealer,
  handleChange,
  handleRemove,
  closeSidebarIfPresent
}) => {
  const sku = item?.sku;
  const cartTitle = item?.short_title ?? item?.displayed_sku;
  // console.log('SingleCartItem:', item);

  const productTypeIsBundle = item?.product_type === 'bundle';
  const productTypeIsNotBundle = !productTypeIsBundle;

  const variant = item?.variant;
  const sourceImage = variant?.image?.url || placeholderImg;
  const options = item?.options;

  const vars = {};
  vars.incompatibleModelText = '';
  if (item?.hasOwnProperty('isCompatible') && !item?.isCompatible) {
    vars.sideBarItemsClassNames = `${vars?.sideBarItemsClassNames} border-amber-400 border-2 py-1 px-2`;
    vars.incompatibleModelText =
      'This subwoofer is not compatible with one or more enclosures in your cart.';
  }

  const preorderInfo = item?.variant?.preorderInfo;
  const isPreorderItem = preorderInfo?.is_pre_order_eligible && preorderInfo?.is_pre_order_enabled;
  const shouldSendAsPreOrderedItem = !item?.variant?.currentlyInStock && isPreorderItem;

  const childProducts = item?.child_products;

  return (
    <div className={{'opacity-50 pointer-events-none': updating}}>
      <div className='relative flex flex-row space-x-2 py-4 px-0'>
        <div className='w-20 h-20 relative overflow-hidden cursor-pointer z-0 border'>
          <ProductLink path={item?.path} closeSidebarIfPresent={closeSidebarIfPresent}>
            <Image
              className={''}
              width={180}
              height={180}
              src={sourceImage}
              alt={item?.variant?.image?.altText || 'Product Image'}
              loading='eager'
            />
          </ProductLink>
        </div>

        <div className='flex flex-1 lg:flex-row lg:justify-between lg:items-start text-base'>
          <div>
            <ProductLink path={item?.path}>
              <span className={'pr-0 active:bg-sky-100'}>{formatCartTitle(cartTitle)}</span>
            </ProductLink>

            <Options sku={sku} options={options} />

            <DisplayChildProductsForBundle childProducts={childProducts} />

            <TwoPrices item={item} isDealer={isDealer} />

            {shouldSendAsPreOrderedItem && (
              <span
                className='border rounded rounded-2 text-xs pl-2 pr-2 border-gray-800'
                style={{
                  backgroundColor: 'rgb(255,251,235)'
                }}>
                Pre-Order Item
              </span>
            )}

            {vars?.incompatibleModelText && (
              <div>
                <span className='text-amber-600'>
                  <strong>Warning:&nbsp;</strong>
                  {vars?.incompatibleModelText}
                </span>
              </div>
            )}
          </div>

          <div className='mt-2 lg:mt-4 flex'>
            <QuantitySelector
              value={quantity}
              handleChange={handleChange}
              size={7}
              isUpdating={updating}
            />
          </div>
        </div>

        <button
          onClick={() => handleRemove(item.sku)}
          aria-label={`Remove ${item.name}`}
          className='transition ease-in-out duration-150 focus:outline-none absolute top-2 right-0 h-4 w-6'>
          <TrashCan color={'rgb(90, 94, 96)'} className={'w-full h-full'} />
        </button>
      </div>
    </div>
  );
};

const formatCartTitle = (title) => {
  if (title?.startsWith('New Year Daily Deal')) {
    const text = 'New Year Daily Deal';
    return (
      <span style={{fontSize: '14.9px', display: 'inline-block', maxWidth: '100%'}}>
        <span style={{fontWeight: 'bold', color: 'black'}}>{text}</span>
        <span
          style={{
            display: 'inline-block',
            wordBreak: 'break-word',
            maxWidth: 'calc(100% - length_of_cyber_monday_deal_text)'
          }}>
          {title?.slice(text?.length)}
        </span>
      </span>
    );
  } else if (title?.startsWith('New Year Sale')) {
    return (
      <span style={{fontSize: '15px'}}>
        <span style={{fontWeight: 'bold', color: 'black'}}>New Year Sale</span>
        <span style={{display: 'inline-block', wordBreak: 'break-word'}}>
          {title?.slice('New Year Sale'?.length)}
        </span>
      </span>
    );
  } else {
    return <span style={{fontSize: '15px'}}>{title}</span>;
  }
};

const ProductLink = ({path, children, closeSidebarIfPresent}) => (
  <Link href={`/products/${path}`} onClick={closeSidebarIfPresent}>
    {children}
  </Link>
);

const Options = ({sku, options}) =>
  options?.length > 0 && (
    <div className='flex items-center pb-1 pt-1'>
      {options.map((option, i) => (
        <div
          key={`${sku}-${option?.name}-${i}`}
          className='text-xs text-gray-600 inline-flex items-center justify-center'>
          <span>{option?.value}</span>
          {i !== options?.length - 1 && <span className='mr-3' />}
        </div>
      ))}
    </div>
  );
